import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import styles from "../css/post.module.scss"

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  const { previous, next } = pageContext
  return (
    <Layout title={post.frontmatter.title}>
      <div className={styles.article}>
        <div className={styles.articleTitle}>{post.frontmatter.title}</div>
        <div className={styles.articleType}>{post.frontmatter.type}</div>
        <div className={styles.articleDate}>{post.frontmatter.date}</div>
        <section
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
      </div>
      {/* <article className="blog-post">
        <header>
          <h1 itemProp="headline">{post.frontmatter.title}</h1>
          <p>{post.frontmatter.date}</p>
        </header>

        <hr />
      </article> */}
      <nav className="blog-post-nav">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ← {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "YYYY.MM")
        type
      }
    }
  }
`
